<template>
<Navbar :user="user" />
    <section class="courses-details-area pt-100 pb-70" v-if="!isLoading">
        <div class="container">
            <div class="courses-details-header">
                <div class="row justify-content-center">
                    <Progress :question="question" :duration="duration" :next="next" />
                </div>
            </div>
        </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import Progress from '@/components/questions/Progress.vue'
export default {
 components: { Navbar, Loading, Progress },
    data(){
        return {
            rating: 4.3,
            isLoading: true,
            token: localStorage.getItem('user_data'),
            errors: {},
            question: {},
            course: {},
            user:{},
            course_content: {},
            attempted: '',
            previousNext: false,
            timerCount: 30,
            next: '',
            previous:'',
            duration:null
        }
    },
        watch: {
        "$route.params.id": {
        handler: function() {
            this.getQuestion()
        },
        deep: true,
        immediate: true,
        },
    },
    methods:{
        getQuestion(){
            let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/quiz_question/public_profile/' + id).then(response => {
                this.question = response.data.question
                this.next = response.data.next
                document.title = this.question.title +' - Training'
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },
        getTime(){
             let id = this.$route.params.id
            axios.get('https://apitraining.vipawaworks.com/api/quiz/get_quiz_time/' + id).then(response => {
                this.duration = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            }).finally(() => {
                setTimeout(function () {
                    this.isLoading =  false
                }.bind(this), 500);
            })
        },

        countDownDuration(){
            if(this.question.duration > 0) {
                setTimeout(() => {
                    this.question.duration -= 1
                    this.setTimeout()
                }, 1000)
            }
        },
        getUser(){
            axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
                this.user = response.data
            }).catch(errors => {
                if (errors.response.status === 401) {
                localStorage.removeItem('user_data')
                this.$router.push({ name: 'Login'})
                }
            })
        },
    },
    created(){
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
        this.getUser() 
        this.getQuestion()
        this.getTime()
    },
}
</script>

<style scoped>

</style>