<template>
    <div class="col-md-9">
        <div class="courses-title">
            <div class="progress mb-4">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"  :style="{'width':(timer / duration ) * 100 + '%'}" :aria-valuenow="duration" aria-valuemin="0" aria-valuemax="100"></div>
                <small class="justify-content-center d-flex position-absolute w-100">
                    <span v-if="timer">{{ timer }} / {{ duration}} sec</span>
                    <span v-else>Time Out</span>
                </small>
            </div>
            <div class="text-start">
                <h6>{{ question.title }}</h6>
                <p>{{ question.description }}</p>
                <div class="list-group" v-if="timer">
                    <button type="button" class="list-group-item list-group-item-action" :class="{active : option.id == attempted.quiz_question_option_id }" v-for="option in question.options" :key="option.id" @click="attemptQuestion(option.id)">
                        {{ option.title }}
                    </button>
                </div>
                <div class="list-group" v-else>
                    <button type="button" class="list-group-item list-group-item-action" :class="{active : option.id == attempted.quiz_question_option_id }" v-for="option in question.options" :key="option.id"  disabled>
                        {{ option.title }}
                    </button>
                </div>
            </div>
        </div>
        <nav aria-label="Page navigation example">
            <ul class="pagination pagination-lg justify-content-between">
                <li class="page-item disabled">
                <a class="default-btn" href="#" tabindex="-1" aria-disabled="true">Skip</a>
                </li>
                <li class="page-item">
                <router-link  class="default-btn" :to="{ name: 'CourseQuizQuestion', params: {id: next}}" v-if="next && timer">Next</router-link>
                <router-link  class="default-btn" :to="{ name: 'CourseQuizResults', params: {id: question.quiz_id}}" v-else>Results</router-link>
                </li>
            </ul>
        </nav>
    </div>              
</template>

<script>
import axios from 'axios'
export default {
    props: ['duration','question','next'],
    data(){
        return {
            attempted: '',
            timer: this.duration
        }
    },
    methods:{
        setTimeout(){
            if(this.timer > 0) {
                setTimeout(() => {
                    this.timer -= 1
                    this.setTimeout()
                }, 1000)
            }
        },
        attemptQuestion(id){
            axios.post('https://apitraining.vipawaworks.com/api/quiz_question_option/take_option/' + id).then(response => {
               this.attempted = response.data
            }).catch(errors => {
                this.errors = errors.response.data.errors
            })
        },
    },
    created(){
        this.setTimeout()
    }
    
}
</script>

<style>

</style>